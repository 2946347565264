import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/Header/index.js"
import OneColumn from "../components/Content/OneColumn"
import CTABlock from "../components/Content/CTABlock"
import TwoColumnWithImage from "../components/Content/TwoColumnWithImage"
import OneColumnScroller from "../components/Content/OneColumnScroller"
import SEO, { seoPropsMapping } from "../components/seo"
import { withPreview } from "gatsby-source-prismic"

const Clients = ({ data }) => {
  const section = data.prismicClientsPage.data

  const contentType = section => {
    let t
    const type = section.slice_type

    switch (type) {
      case "cta_block":
        t = <CTABlock key={`section-${section.primary.section_id}`} data={section} />
        break
      case "1-column_container-small":
        t = <OneColumn key={`section-${section.primary.section_id}`} data={section} />
        break
      case "1-column-repeatable":
        t = <OneColumnScroller key={`section-${section.primary.section_id}`} data={section} />
        break
      case "2-column":
        t = <TwoColumnWithImage key={`section-${section.primary.section_id}`} data={section} />
        break
      default:
        break
    }
    return t
  }

  const seoProps = seoPropsMapping(section, {
    seo_title: "Our Client Portfolio & Case Studies",
  })
  return (
    <Layout>
      <SEO {...seoProps} />
      <Header
        image={section.hero_banner_image}
        title={section.hero_title.text}
        subtitle={section.hero_subtitle}
        text_alignment={section.hero_text_alignment || "left"}
      />
      <div className="clients-page">{section.body.map((section, i) => contentType(section))}</div>
    </Layout>
  )
}

export default withPreview(Clients)

export const clientQuery = graphql`
  {
    prismicClientsPage {
      uid
      data {
        seo_title
        seo_description
        seo_keywords
        og_title
        og_description
        og_image {
          fixed(width: 1200) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        og_type

        twitter_site
        twitter_creator
        twitter_title
        twitter_description
        twitter_image_link {
          fixed(width: 4096) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        page_title
        hero_text_alignment
        hero_banner_image {
          fluid(maxWidth: 1400) {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }
        hero_title {
          text
        }
        hero_subtitle
        body {
          ... on PrismicClientsPageBody1ColumnRepeatable {
            slice_label
            slice_type
            primary {
              section_id
              section_theme
              section_background_color
              section_title {
                html
              }
              section_description {
                html
              }
            }
            items {
              copy {
                html
              }
              author {
                html
              }
              job_title {
                html
              }
              cta_text
              cta_url {
                url
                target
              }
            }
          }
          ... on PrismicClientsPageBody2Column {
            slice_label
            slice_type
            primary {
              section_id
              section_theme
              section_background_color
              copy {
                html
              }
              image {
                fixed(width: 500) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
          }
          ... on PrismicClientsPageBodyCtaBlock {
            primary {
              section_id
              section_theme
              title {
                html
              }
              description {
                html
              }
              cta_button_link {
                target
                url
              }
              cta_button_text
            }
            slice_type
          }
        }
      }
    }
  }
`
